const imageKey = ['ELN-A', 'ELN-B', 'ELN-C', 'ELN-D', 'HB', 'CT'];
export const ObjUpsert = (array: Record<string, any>[], matchKeys: Record<string, any>, updates: Record<string, any>): Record<string, any>[] => {
	let keyFound = false;

	const updatedArray = array.map((obj) => {
		const matches = Object.keys(matchKeys).every((key) => obj[key] === matchKeys[key]);
		if (matches) {
			keyFound = true;
			return { ...obj, ...updates };
		}
		return obj;
	});

	if (!keyFound) {
		updatedArray.push({ ...matchKeys, ...updates });
	}

	return updatedArray;
};

export const calculatePricing = (ratePlan: RatePlan, isTour: boolean = false) => {

	const rateTable = [];
	const { base, extra, inc } = ratePlan;
	const max = isTour ? 24 : 10;
	for (let i = 1; i <= max; i++) {
		const price = i <= inc ? base : base + (i - inc) * extra;
		rateTable.push({
			person: `${i} ${i > 1 ? 'persons' : 'person'}`,
			price: price,
		});
	}
	return rateTable;
};
export const getRandomSubset = <T>(count: number, arr: T[]): T[] => {
	if (count >= arr.length) {
		return [...arr];
	}

	const shuffledArray = arr.sort(() => 0.5 - Math.random());
	return shuffledArray.slice(0, count);
};

export const getUserCurrency = (countries, countryCode: string) => {
	const countryInfo = countries.find((x) => x.countryCode === countryCode);
	return countryInfo?.currencyCode || 'USD';
};

export const shuffleArray = <T>(array: T[]): T[] => {
	for (let i = array.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		[array[i], array[j]] = [array[j], array[i]];
	}
	return array;
};

export const generateImages = (item) => {
	let images = []
	let thumbnail = null;
	const transfersType = ['PRIVATE_TRANSFER', 'SHARED_TRANSFER', 'PRIVATE_TRANSFER_WITH_TOUR', 'AIRPORT_TRANSFER'];
	if (transfersType.includes(item.listing_type)) {
		images = images.concat(imageURLGenerator('VAN'));
		if (item.title.toLowerCase().includes('underground') || item.code.includes('UR')) {
			images = images.concat(imageURLGenerator('UR'));
		}
	}
	const key = imageKey.find((key) => item.code.includes(key));
	if (key)
		images = images.concat(imageURLGenerator(key));
	images = shuffleArray(images);
	thumbnail = images[Math.floor(Math.random() * images.length)];

	return { images, thumbnail };
};

export const imageURLGenerator = (key) => {
	let images = [];
	for (let i = 1; i <= 5; i++) {
		images.push(`/images/${key}/${key}${i}.webp`);
	}
	return shuffleArray(images);
};
export const allImages = () => {
	const images = {};
	imageKey.forEach((key) => {
		images[key] = imageURLGenerator(key);
	});
	return images;
};
export const currencyConverter = (rates: any, amount: number, sourceCurrency: string, targetCurrency: string) => {
	const sourceToTarget = amount / rates[sourceCurrency];
	return (sourceToTarget * rates[targetCurrency]).toFixed(2);
};
