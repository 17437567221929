<template>

</template>
<script setup>
const { gtag } = useGtag();
const store = useBookingStore();
const { isGranted, cartItems } = storeToRefs(store);
const consentEnable = () => {
    if (isGranted.value === false) {
        gtag('consent', 'update', {
            'ad_user_data': 'granted',
            'ad_personalization': 'granted',
            'ad_storage': 'granted',
            'analytics_storage': 'granted',
            wait_for_update: 500,
        });
        store.setIsGranted();
    }
}

watch(cartItems, async () => {
	const cartSize = computed(() => cartItems.value.length);
	if (cartSize.value > 0 && process.client && window) {
		await nextTick(() => {
			consentEnable();
		});


	}
}, { deep: true, immediate: true });
</script>