import { defineStore } from 'pinia';
import store from '~/data/store.json';
const { rates } = store;
export const useCurrencyStore = defineStore('currency', {
    state: () => ({
        currency: null,
        locale: null,
        currencyData: null,
        rates: rates
    }),
    actions: {
        setInitialData(locale, currencyData) {
            this.currency = currencyData.currencyCode,
                this.locale = locale
            this.currencyData = currencyData;
        },

    },
    getters: {
        getUserCurrency: (state) => state.currency,
        getUserLocale: (state) => state.locale,
        getCurrencyData: (state) => state.currencyData,
        getRates: (state) => state.rates
    },
    persist: {
        storage: piniaPluginPersistedstate.cookies({ sameSite: 'lax' }),
    }
});