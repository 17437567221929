export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1.0, maximum-scale=2.0, user-scalable=yes"},{"name":"robots","content":"index, follow"},{"name":"description","content":"Diskubre Travel Palawan Official Website - Book your tours , airport transfers, private and shared transportations, and activities in Palawan Island, Philippines."},{"http-equiv":"ScreenOrientation","content":"autoRotate:disabled"}],"link":[{"rel":"dns-prefetch","href":"https://www.googletagmanager.com/"},{"rel":"dns-prefetch","href":"https://cdn.jsdelivr.net/"},{"rel":"dns-prefetch","href":"https://fonts.googleapis.com/"},{"rel":"apple-touch-icon","href":"/apple-touch-icon.png","sizes":"180x180","type":"image/png"},{"rel":"icon","href":"/favicon-32x32.png","sizes":"32x32","type":"image/png"},{"rel":"icon","href":"/favicon-16x16.png","sizes":"16x16","type":"image/png"},{"rel":"manifest","href":"/manifest.webmanifest"}],"style":[],"script":[],"noscript":[],"htmlAttrs":{"lang":"en"},"charset":"utf-8","viewport":"width=device-width, initial-scale=1"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const appSpaLoaderTag = "div"

export const appSpaLoaderAttrs = {"id":"__nuxt-loader"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"

export const crawlLinks = true

export const spaLoadingTemplateOutside = false